const convertImageMap = (images = [], type = 'fluid') =>
  images.reduce((acc, el) => {
    const props = {}
    props.alt = el.title
    props.draggable = false
    props[type] = el.image.childImageSharp[type]

    acc[el.title] = props
    return acc
  }, {})

export default convertImageMap
